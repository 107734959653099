import ButtonWithArrow from 'components/ButtonWithArrow';
import Error from 'components/Error';
import { useRouter } from 'next/router';
import React from 'react';
import { getDocumentByUid } from 'helpers/Prismic';
import globalProps from 'utils/globalProps';

function ErrorPage() {
  const router = useRouter();
  return (
    <div>
      <Error code={404} />
      {/* <ButtonWithArrow
        arrowDirection="back"
        title="Return To Homepage"
        handleClick={() => router.push('/')}
      /> */}
    </div>
  );
}

export default ErrorPage;

export async function getStaticProps({ preview = false, previewData }) {
	const {
		props: { global },
	} = await globalProps();

	const { ref } = previewData || {};

	const homePage = await getDocumentByUid('landing-page', 'homepage-new', ref);

	const homepageContent = homePage.data;

	return {
		props: {
			global,
			homepageContent,
			preview,
		},
		revalidate: 3600,
	};
}
